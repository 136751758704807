import React from "react";
import {Link} from "react-router-dom";
import styled from 'styled-components'
import {Container} from './CommonStyles'


const Navigation = (props) => {
    return (
        <NavWrapper>
            <Container>
                <NavigationBlock>
                    <Link to='/'>Общая информация</Link>
                    <Link to='/how_works'>Как мы работаем</Link>
                    <Link to='/connect'>Как подключиться</Link>
                    <Link to='/picture'>Как сделать фотографии</Link>
                    <Link to='/conditions'>Условия</Link>
                    <Link to='/promo'>Промоматериалы</Link>
                </NavigationBlock>
            </Container>
        </NavWrapper>
    )
}

const NavWrapper = styled.div`
  background: #262626;
  display:flex;
  align-items: center;
  padding: 36px 0 21px 0;
  overflow: scroll;
  // firefox scrollbar remove
  scrollbar-width: none;
  // webkit scrollbar remove
  ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
`
const NavigationBlock = styled.nav`
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  width: 1280px;
  & a{
    white-space: nowrap;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    color: #fff;
  }
`

export default Navigation;