import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import {AnimatePresence} from 'framer-motion'
//components
import Navigation from "./components/Navigation";
import CommonInfo from "./components/content/CommonInfo";
import HowWeWorks from "./components/content/HowWeWorks";
import HowToConnect from "./components/content/HowToConnect";
import HowMakePicture from "./components/content/HowMakePicture";
import Conditions from "./components/content/Conditions";
import Promo from "./components/content/Promo";
import FormPopup from "./components/content/FormPopup";
import Presentation from "./components/Presentation";
import SetImage from "./components/SetImage";

function App() {

    const [showPopup, setShowPopup] = useState(false);

    const presentationTransition = {
        in: {
            y: 0,
            opacity: 1
        },
        out: {
            y: '5%',
            opacity: .7
        }
    };

    const buildDefaultPresentationSettings = (mobileBgSet) => {
        return new Object({
            transition: presentationTransition,
            setShowPopup: setShowPopup,
            mobileBg: mobileBgSet,
        })
    }

    return (
    <MainWrapper>
        <Router>
            <AnimatePresence exitBeforeEnter>
                <Switch>
                    <Route path="/promo">
                        <SetImage Component={Presentation} data={buildDefaultPresentationSettings("#333")} type={"COMMON_INFO"} />
                    </Route>
                    <Route path="/conditions">
                        <SetImage Component={Presentation} data={{...buildDefaultPresentationSettings("#333")}} type={"COMMON_INFO"} />
                    </Route>
                    <Route path="/picture">
                        <SetImage Component={Presentation} data={{...buildDefaultPresentationSettings("")}} type={"HOW_MP"} />
                    </Route>
                    <Route path="/connect">
                        <SetImage Component={Presentation} data={{...buildDefaultPresentationSettings("#333")}} type={"COMMON_INFO"} />
                    </Route>
                    <Route path="/how_works">
                        <SetImage Component={Presentation} data={{...buildDefaultPresentationSettings("rgb(254, 201, 60)")}} type={"HOW_WW"} />
                    </Route>
                    <Route path="/">
                        <SetImage Component={Presentation} data={{...buildDefaultPresentationSettings('#333')}} type={"COMMON_INFO"} />
                    </Route>
                </Switch>
            </AnimatePresence>
            <Navigation />
            <Switch>
                <Route path="/promo">
                    <Promo />
                </Route>
                <Route path="/conditions">
                    <Conditions />
                </Route>
                <Route path="/picture">
                    <HowMakePicture />
                </Route>
                <Route path="/connect">
                    <HowToConnect setShowPopup={setShowPopup}/>
                </Route>
                <Route path="/how_works">
                    <HowWeWorks />
                </Route>
                <Route path="/">
                    <CommonInfo />
                </Route>
            </Switch>
        </Router>
        {
            showPopup
                ? <FormPopup setShowPopup={setShowPopup}/>
                : null
        }
    </MainWrapper>
    );
}

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

// >1280
//<=1280
//<=992
//<=768
//<=576
//<=480
//<=360

export default App;
