import React from "react";
import {Container} from './CommonStyles';
import styled from "styled-components";
import {motion} from "framer-motion";

const Presentation = ({
    transition,
    setShowPopup,
    mobileBg,
    bg,
    logo,
    pcSVG,
    themeSet,
}) => {

    const theme = {
        theme: themeSet,
        mobileBg: mobileBg,
    };

    return (
        <motion.div
            initial='out'
            animate='in'
            exit='out'
            variants={transition}
        >
            <PresentationWrapper background={bg} {...theme}>
                <Container>
                    <LocalHeader>
                        <LogoWrapper {...theme}>
                            <img src={logo} alt=""/>
                            <SeparatorLine {...theme}/>
                            <a href="https://sitiy.ru/" target="_blank">Заказать еду</a>
                        </LogoWrapper>
                        <a href="https://lk.sitiy.ru" target="_blank">
                            <PersonalCabinet {...theme}>
                                <img src={pcSVG} alt=""/>
                                <p>Личный кабинет</p>
                            </PersonalCabinet>
                        </a>
                    </LocalHeader>
                    <ContentWrapper {...theme}>
                        <h1>
                            Экспресс-подключение к сервису Сытый
                        </h1>
                        <MakeOrderButton onClick={() => setShowPopup(true)} {...theme}>
                            <p>Заполнить заявку</p>
                        </MakeOrderButton>
                    </ContentWrapper>
                </Container>
            </PresentationWrapper>
        </motion.div>
    )
}

export default Presentation;



const PresentationWrapper = styled.div`
      height: 600px;
      background: url(${({background}) => background}) 100% 100% no-repeat;
      color: #000;
      background-size: cover;
      
      @media (max-width: 768px) {
        background: url(${({background}) => background}) 70% 100% no-repeat;
      }
      @media (max-width: 576px) {
        height: calc(100vh - 92px);
        min-height: 560px;
        background: ${({mobileBg}) => mobileBg} 100% 100% no-repeat;
        font-size: 26px;
      }
    `
const LocalHeader = styled.header`
      display: flex;
      justify-content: space-between;
      padding: 19px 0;
      
      & a{
        text-decoration: none;
      }
      
      @media (max-width: 576px) {
        padding: 30px 0;
      }
    `
const LogoWrapper = styled.div`
      font-size: 16px;
      display: flex;
      align-items: center;
      
      & img{
        margin-right: 36px;
        color: ${({theme}) => theme === 'dark' ? '#000' : theme === "light" ? "#fff" : theme ? theme : null};
      }
      & a{
        color: ${({theme}) => theme === 'dark' ? '#000' : theme === "light" ? "#fff" : theme ? theme : null};
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        text-decoration: underline;
      }
      
      @media (max-width: 576px) {
        & img{
          margin-right: 12px;
       }
      }
    `
const SeparatorLine = styled.div`
      width: 1px;
      height: 38px;
      margin-right: 36px;
      background: ${({theme}) => theme === 'dark' ? '#000' : theme === "light" ? "#fff" : theme ? theme : null};
        
      @media (max-width: 576px) {
        margin-right: 12px;
      }
    `
const PersonalCabinet = styled.button`
      display: flex;
      align-items: center;
      padding: 13px 18px;
      border-radius: 6px;
      border: none;
      background: ${({theme}) => theme === 'dark' ? '#000' : theme === "light" ? "#fff" : theme ? theme : null};
      color: ${({theme}) => theme === 'dark' ? '#fff' : theme === "light" ? "#000" : theme ? theme : null};
      cursor: pointer;
      img {
        margin-right: 10px;
      }
      
      @media (max-width: 576px) {
        padding: 12px 13px;
        img {
          margin-right: 0;
        }
        & p {
          display: none;
        }
      }
    `
const ContentWrapper = styled.div`
      padding: 74px 0;
      width: 50%;
      color: ${({theme}) => theme === 'dark' ? '#000' : theme === "light" ? "#fff" : theme ? theme : null};
      h1{
        font-size: 36px;
        line-height: 42px;
        font-style: normal;
        font-weight: 700;
      }
      @media (max-width: 768px) {
        width: 60%;
      }
      @media (max-width: 576px) {
      padding: auto 0;
      height: 100%;
      position: relative;
        width: 100%;
        h1 {
            line-height: 30px;
            font-size: 26px;
        }
      }
      @media (max-width: 320px) {
      padding: 0;
      }
    `
const MakeOrderButton = styled.button`
      margin-top: 67px;
      padding: 12px 26px;
      background: none;
      border: 1px solid ${({theme}) => theme === 'dark' ? '#000' : theme === "light" ? "#fff" : theme ? theme : null};
      border-radius: 6px;
      color: ${({theme}) => theme === 'dark' ? '#000' : theme === "light" ? "#fff" : theme ? theme : null};
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      cursor: pointer;
      transition: all .2s ease;
      
      @media (max-width: 576px) {
        margin-top: 52px;
      }
      :hover{
        background-color: ${({theme}) => theme === 'dark' ? '#000' : theme === "light" ? "#fff" : theme ? theme : null};
        color: ${({theme}) => theme === 'dark' ? '#fff' : theme === "light" ? "#000" : theme ? theme : null};
      }
    `
const  Preview = styled.div`
      margin-top: 69px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      width: 406px;
    
      @media (max-width: 576px) {
          width: 80%;
          margin-top: 33px;
          font-size: 16px;
          line-height: 19px;
      }
    `