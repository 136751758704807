import React from "react";
import styled from 'styled-components';
import {SectionHeader, Container} from '../CommonStyles';


const Promo = (props) => {

    return (
        <MainWrapper>
            <Container>
                <Temp>
                    <h2>Раздел в разработке</h2>
                </Temp>
            </Container>
        </MainWrapper>
    )
}
const MainWrapper = styled.div`

`
const Temp = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background: #D1E231;
  border-radius: 10px;
  
  h2 {
    color: #fff;
    font-size: 52px;
  }
`
export default Promo;