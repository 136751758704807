import React from "react";
import Presentation from "./Presentation";
//bgs
import bgComInf from '../resource/presentation_commoninfo_bg.png'
import bgHWW from '../resource/presentation_howweworks_bg.png'
import bgHMP from '../resource/presentation_makepicture_bg.png';

//common
import logoWhite from '../resource/logo_white.svg'
import logoBlack from '../resource/logo_black.svg';
import pcSVGBlack from '../resource/user_pc_black.svg'
import pcSVGWhite from '../resource/user_pc_white.svg';
import {motion} from "framer-motion";

const SetImage = ({Component, data, type}) => {
    console.log(data)
    function buildSettings(bg, logo, pcSVG, themeSet) {
        return {
            ...data,
            bg: bg,
            logo: logo,
            pcSVG: pcSVG,
            themeSet: themeSet,
        }
    }

    switch (type) {
        case "COMMON_INFO":
            return <Component {...buildSettings(bgComInf, logoWhite, pcSVGBlack, 'light')}/>
        case "HOW_WW":
            return <Component {...data} {...buildSettings(bgHWW, logoBlack, pcSVGWhite, 'dark')}/>
        case "HOW_MP":
            return <Component {...data} {...buildSettings(bgHMP, logoBlack, pcSVGWhite, 'dark')}/>
    }
}

export default SetImage;