import React from "react";
import styled from "styled-components";
import {SectionHeader, Container} from '../CommonStyles';
//img
import pin from '../../resource/how_connect/pin.svg'
import list from '../../resource/how_connect/list.svg'
import quality from '../../resource/how_connect/quality.svg'
import market from '../../resource/how_connect/market.svg'
import order from '../../resource/how_connect/order.svg'
import salad from '../../resource/how_connect/salad.svg'
import id_card from '../../resource/how_connect/id_card.svg'
import picture from '../../resource/how_connect/picture.svg'
import rocket from '../../resource/how_connect/rocket.svg'


const HowToConnect = ({setShowPopup}) => {

    return (
        <MainWrapper>
            <Container>
                <SectionHeader>
                    Простые шаги для подключения к сервису
                </SectionHeader>
                <Instruction>
                    <InstructionHeader>
                        <ListPoint>1</ListPoint>
                        <h3>
                            <a
                                onClick={() => setShowPopup(true)}
                                style={
                                    {
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                    }
                                }>Подать заявку</a> и зарегистрироваться в личном кабинете <a href="https://lk.sitiy.ru" target="_blank">lk.sitiy.ru</a>
                        </h3>
                    </InstructionHeader>
                    <InstructionItem>
                        <SubContainer>
                            <Description>
                                <p>
                                    После заполнения формы заявки с вами свяжется наш специалист для ответа на вопросы и помощи в дальнейших шагах
                                </p>
                            </Description>
                            <ConditionsList listColumns={2}>
                                <header>
                                    <h4>
                                        На что мы обращаем внимание при одобрении вашей заявки:
                                    </h4>
                                </header>
                                <ul>
                                    <li>
                                        <ConditionsIco><img src={pin} alt="map pin"/></ConditionsIco>
                                        <p>Местонахождение, для нас важна близость к нашим распределительным центрам</p>
                                    </li>
                                    <li>
                                        <ConditionsIco><img src={market} alt="market ico"/></ConditionsIco>
                                        <p>Наличие необходимой инфраструктуры и места для сборки заказов</p>
                                    </li>
                                    <li>
                                        <ConditionsIco><img src={list} alt="list ico"/></ConditionsIco>
                                        <p>Возможность приготовления и сборки заказов в соответствии с нашими требованиями</p>
                                    </li>
                                    <li>
                                        <ConditionsIco><img src={order} alt="order ico"/></ConditionsIco>
                                        <p>Качество изготавливаемой продукции, наличие соответствующих сертификатов</p>
                                    </li>
                                    <li>
                                        <ConditionsIco><img src={quality} alt="quality ico"/></ConditionsIco>
                                        <p>Соответствие качества продукции и соотношения цена/качество</p>
                                    </li>
                                </ul>
                            </ConditionsList>
                        </SubContainer>
                    </InstructionItem>
                </Instruction>
                <Instruction>
                    <InstructionHeader>
                        <ListPoint>2</ListPoint> <h3>Прислать реквизиты и подписать договор </h3>
                    </InstructionHeader>
                    <InstructionItem>
                        <SubContainer>
                            <Description>
                                <p>
                                    Необходимо прислать ваши реквизиты (карточку компании) на почту <a href="mailto:help@menu4me.ru">help@menu4me.ru</a>. В ответ менеджер отправит вам заполненный договор, который необходимо подписать и отправить скан в ответ. Оригинал договора можно будет передать на этапе тестового запуска.
                                </p>
                            </Description>
                        </SubContainer>
                    </InstructionItem>
                </Instruction>
                <Instruction>
                    <InstructionHeader>
                        <ListPoint>3</ListPoint> <h3>Подготовить и передать меню и необходимые материалы</h3>
                    </InstructionHeader>
                    <InstructionItem>
                        <SubContainer>
                            <Description>
                                <p>
                                    После прохождения проверки и заключения договора начинается подготовка к запуску. От вас будет необходимо предоставить следующие материалы
                                </p>
                            </Description>
                            <ConditionsList listColumns={1}>
                                <ul>
                                    <li>
                                        <ConditionsIco><img src={pin} alt="map pin"/></ConditionsIco>
                                        <p>Меню, ассортимент вашей продукции</p>
                                    </li>
                                    <li>
                                        <ConditionsIco><img src={market} alt="market ico"/></ConditionsIco>
                                        <p>Контактные и прочие данные вашей кухни для публикации на сайте</p>
                                    </li>
                                    <li>
                                        <ConditionsIco><img src={list} alt="list ico"/></ConditionsIco>
                                        <p>Фотографии блюд и напитков</p>
                                    </li>
                                </ul>
                                <Afterword>Мы отправим на почту инструкцию о подготовке материалов</Afterword>
                            </ConditionsList>
                        </SubContainer>
                    </InstructionItem>
                </Instruction>
                <Instruction>
                    <InstructionHeader>
                        <ListPoint>4</ListPoint> <h3>Выполнить технический запуск вашей кухни</h3>
                    </InstructionHeader>
                    <InstructionItem>
                        <SubContainer>
                            <Description>
                                <p>
                                    Фактически мы проводим первые тестовые заказы в закрытом режиме и обучаем вас сборке заказов и взаимодействие с нашим сервисом.
                                </p>
                            </Description>
                            <Afterword>
                                Подробную информацию и все необходимые инструкции вам будут предоставлены
                            </Afterword>
                        </SubContainer>
                    </InstructionItem>
                </Instruction>
                <Instruction>
                    <InstructionHeader>
                        <ListPoint><img src={rocket} alt="rocket"/></ListPoint> <h3>По окончанию подготовки мы согласовываем дату запуска и начинаем</h3>
                        <ConditionsList>


                        </ConditionsList>
                    </InstructionHeader>
                </Instruction>
            </Container>
        </MainWrapper>
    )
}

const MainWrapper = styled.div`
  padding: 70px 0 205px 0;
`
const Instruction =  styled.section`
  margin-top: 65px;
  display: flex;
  flex-direction: column;
  
  :last-child span {
    padding: 10px 15px;
  }
`
const InstructionHeader = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 30px;
  
  @media (max-width: 576px){
    flex-direction: column;
    align-items: flex-start;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    
    & h3 {
      margin-top: 10px;
    }
  }
`
const InstructionItem = styled.div`

`
const ListPoint = styled.div`
  min-width: 45px;
  min-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #D1E231;
  border-radius: 50%;
  margin: 0 22px 0 0;
`
const SubContainer = styled.div`
  padding: 0 80px;
  
  @media  (max-width: 576px) {
    padding: 0;
  }
`
const Description = styled.div`
  padding-top: 20px;
  width: 659px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  
  @media (max-width: 992px) {
      width: 100%;
    }
`
const ConditionsList = styled.div`
  padding-top: 40px;
  
  & header {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
  }
  
  & ul {
    margin-top: 24px;
    display: grid;
    grid-template-columns: ${({listColumns}) => listColumns === 1 ? "1fr" : listColumns === 2 ? "1fr 1fr" :  null};
    column-gap: 121px;
    row-gap: ${({listColumns}) => listColumns === 1 ? "25px" : listColumns === 2 ? "28px" :  null};
    
    @media (max-width: 992px) {
      column-gap: 50px;
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  & ul li {
    display: flex;
    align-items: center;
    
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 26px;
    }
    
    :first-child{
      img {
        width: 40%;
      }
    }
  }
`
const Afterword = styled.p`
  margin-top: 13px;
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
`
const ConditionsIco = styled.div`
  min-width: 40px;
  min-height: 40px;
  background: #D1E231;
  border-radius: 50%;
  margin-right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  & img {
    width: 55%;
    height: 25px;
  }
`

export default HowToConnect;