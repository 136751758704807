import styled from "styled-components";


export const Container = styled.div`
  margin: 0 auto;
  @media (min-width: 1280px){
    width: 1280px;
  }
  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 5%;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 0 20px;
  }
`
export const SectionHeader = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  
  @media (max-width: 576px) {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
  }
`