import React, {useState, useEffect} from "react";
import styled from 'styled-components';
import {SectionHeader, Container} from '../CommonStyles';
//img
import prev_image from '../../resource/common_info/prev_image.png'
import benefits_img from '../../resource/common_info/benefits.png'
import feature1 from '../../resource/common_info/feature1.png'
import feature2 from '../../resource/common_info/feature2.png'
import feature3 from '../../resource/common_info/feature3.png'
import feature4 from '../../resource/common_info/feature4.png'
import arrow from '../../resource/common_info/faq_arrow.svg'

const QuestionBlock = ({callback, header, answer, link, showAnswer}) => {

    return (
        <QuestionBlockWrapper onClick={callback} showAnswer={showAnswer}>
            <header><p>{header}</p></header>
            <Answer showAnswer={showAnswer}>
                {answer + " "}<a href={"https://" + link} target="_blank">{link}</a>
            </Answer>
        </QuestionBlockWrapper>
    )
}

const CommonInfo = (props) => {
    const [questions, setQuestions] = useState([]);
    useEffect(() => {
        setQuestions([
            {
                header: "Сколько заказов мы будем получать?",
                answer: "Как правило, ежедневная выручка партнёра через 2-3 недели после подключения колеблется в пределах некого коридора с разбегом +-20% от среднедневной, т.е. спрос на продукцию партнёров стабилен при сохранении качества и растёт вместе с ростом сервиса. Но какие абсолютные значения будут покажет только время. Всё будет решать пользователь сервиса, оценивая качество и стоимость предложенных вами блюд",
                showAnswer: false,
            },
            {
                header: "А если придёт много заказов и мы не успеем?",
                answer: "Не стоит переживать. Максимальное количество заказов вы регулируете сами. Вы можете ограничивать количество позиций в меню, которые готовы будете собрать и передать нам. При формировании меню на тот или иной день вы включаете, те блюда из ассортимента что планируете готовить и указываете максимальное количество порций.",
                showAnswer: false,
            },
            {
                header: "Какая зона доставки?",
                answer:  `Информацию о доставке и зоны доставки вы можете увидеть в разделе "О доставке" на сайте`,
                link: "sitiy.ru/dostavka",
                showAnswer: false,
            },
            {
                header: "Нам нужны курьеры для доставки?",
                answer: "Доставку до покупателя осуществляется нашей службой доставки. При этом в некоторых случаях партнёры осуществляют доставку продукции до ближайшего распределительного центра. Оставьте заявку и получите подробную информацию после знакомства с вашей кухней",
                showAnswer: false,
            },
            {
                header: "Где взять упаковку и посуду?",
                answer: "Всем новым партнёрам мы предоставляем стартовый комплект посуды и упаковки. Также нами собрана вся информация о лучших поставщиках проверенной и качественной тары. Мы предоставим вам информацию после заключения договора",
                showAnswer: false,
            },
        ])
    }, []);
    function showAnswer(id) {
        return function () {
            let temp = questions[id].showAnswer;
            //reset open
            setQuestions(prev => [...prev.map(el => {
                el.showAnswer = false
                return el
            })]);
            //set open
            setQuestions(prev => {
                let tempArr = [...prev]
                tempArr[id].showAnswer = !temp;
                return tempArr;
            })
        }
    }

    return (
        <MainWrapper>
            <Preview>
                <Container>
                    <PreviewWrapper>
                        <p>Мы объединяем столовые, кафе и рестораны в одном сервисе, принимаем заказы и доставляем собственной курьерской службой</p>
                        <img src={prev_image} alt=""/>
                    </PreviewWrapper>
                </Container>
            </Preview>
            <Benefits>
                <Container>
                    <header><SectionHeader>Преимущества</SectionHeader></header>
                    <BenefitsWrapper>
                        <BenefitCard>
                            <img src={benefits_img} alt="benefit image"/>
                            <h3>
                                Готовый канал продаж
                            </h3>
                            <p>
                                Тысячи клиентов ежедневно заказывают доставку. Предложите им свою продукцию у нас
                            </p>
                        </BenefitCard>
                        <BenefitCard>
                            <img src={benefits_img} alt="benefit image"/>
                            <h3>
                                Лояльность к бренду
                            </h3>
                            <p>
                                Сотрудничество с нами - это возможность рассказать о вашем бренде и продукции огромной армии наших постоянных клиентов
                            </p>
                        </BenefitCard>
                        <BenefitCard>
                            <img src={benefits_img} alt="benefit image"/>
                            <h3>
                                Стабильный доход
                            </h3>
                            <p>
                                Займите своё место среди партнёров и получайте дополнительный прогнозируемый доход ежедневно без доп.затрат
                            </p>
                        </BenefitCard>
                    </BenefitsWrapper>
                </Container>
            </Benefits>
            <Features>
                <Container>
                    <header>
                        <SectionHeader>
                            Особенности сервиса
                        </SectionHeader>
                    </header>
                    <FeaturesWrapper>
                        <Feature color='#FFF8BD'>
                            <img src={feature1} alt=""/>
                            <header><FeatureHeader>Работаем по предзаказам</FeatureHeader></header>
                            <p>
                                К примеру, заказать доставку обеда можно до 10:00, а ужин до 17:00. После этого времени приём заказов невозможен
                            </p>
                        </Feature>
                        <Feature color='#E0F3F0'>
                            <img src={feature2} alt=""/>
                            <header><FeatureHeader>Строго по времени</FeatureHeader></header>
                            <p>
                                Еда доставляется строго в период времени, который был выбран при оформлении заказа. У нас нет индивидуальной доставки по запросу клиента
                            </p>
                        </Feature>
                        <Feature color='#E0F3F0'>
                            <img src={feature3} alt=""/>
                            <header><FeatureHeader>Мультизаказ</FeatureHeader></header>
                            <p>
                                Кухни получают один большой мультизаказ, собирают и передают его курьерам единовременно и полностью
                            </p>
                        </Feature>
                        <Feature color='#DFF9D9'>
                            <img src={feature4} alt=""/>
                            <header><FeatureHeader>Все заказы в одном месте</FeatureHeader></header>
                            <p>
                                Мы распределяем заказы по курьерам и доставляем последовательно, каждый курьер по маршруту в своей зоне доставки
                            </p>
                        </Feature>
                    </FeaturesWrapper>
                </Container>
            </Features>
            <FAQ>
                <Container>
                    <SectionHeader>
                        Часто задаваемые вопросы
                    </SectionHeader>
                    <FAQWrapper>
                        {questions.map((el, id) => <QuestionBlock callback={showAnswer(id)} key={id + "keyLIUGJBjldkshf"} {...el}/>)}
                    </FAQWrapper>
                </Container>
            </FAQ>
        </MainWrapper>
    )
}

const MainWrapper = styled.div`
  @media (max-width: 576px) {
    background: url(${prev_image}) 130% 0% no-repeat;
    background-size: 177px;
  }
`
const Preview = styled.section`
`
const PreviewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  & img {
    width: 440px;
  }
  & p {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 46px;
    width: 591px;
  }
  
  @media (max-width: 768px) {
    & img {
      width: 330px;
    }
  }
  @media (max-width: 576px) {
    padding: 71px 0;
    & img {
      display: none;
    }
    & p {
      width: 300px;
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 32px;
    }
  }
`
const Benefits = styled.section`
  background: #FCFCFC;
  display: flex;
  flex-direction: column;
  padding: 84px 0;
`
const BenefitsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 47px;
`
const BenefitCard = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 33.333333%;
  min-width: 311px;
  
  :nth-child(1n + 4) {
    margin-top: 97px;
  }
  & img {
    border-radius: 50%;
    width: 160px;
  }
  & h3 {
    margin-top: 27px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
  & p {
    margin-top: 36px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }
  
  @media (max-width: 1280px) {
    :nth-child(1n + 4) {
      margin-top: 0;
    }
  }
  
  @media (max-width: 768px) {
    flex: 0 0 100%;
    margin-top: 47px;
    max-width: 100%;
  }
  
  @media (max-width: 576px) {
    flex: 0 0 100%;
    margin-top: 47px;
    max-width: 100%;
    padding: 47px 20px;
    :first-child{
      padding-top: 0;
      margin-top: 0;
    }
    :nth-child(1n) {
      margin-top: 0;
    }
  }
`
const Features = styled.section`
  padding: 100px 0;
`
const FeatureHeader = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
`
const FeaturesWrapper = styled.div`
  margin-top: 42px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const Feature = styled.div`
  padding: 40px;
  max-width: 436px;
  background: ${({color}) => color};
  border-radius: 46px 0 46px 0;
  flex: 0 0 50%;
  
  :nth-child(1n + 3) {
    margin-top: 80px;
  }
  :nth-child(2n + 1) {
    margin-right: 80px;
  }
  & header {
    margin-top: 20px;
  }
  & p {
    margin-top: 20px;
    margin-bottom: 25px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
  }
  
  @media (max-width: 1280px) {
    :nth-child(1n + 3) {
      margin-top: 50px;
    }
    :nth-child(2n + 1) {
      margin-right: 50px;
    }
  }
  @media (max-width: 1024px) {
    :nth-child(1n + 3) {
      margin-top: 20px;
    }
    :nth-child(2n + 1) {
      margin-right: 20px;
    }
  }
  @media (max-width: 992px) {
    flex: 0 0 100%;
    max-width: 436px;
    margin-bottom: 50px;
    :last-child{
      margin-bottom: 0;
    }
    :nth-child(1n + 3) {
      margin-top: 0;
    }
    :nth-child(2n + 1) {
      margin-right: 0;
    }
  }
  @media (max-width: 768px){
  }
`
const FAQ = styled.section`
  padding: 100px 0;
  background: #FCFCFC;
  
  @media (max-width: 1280px) {
    padding: 0;
  }
`
const FAQWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const QuestionBlockWrapper = styled.div`
  cursor: pointer;
  width: 80%;
  & p {
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 37px;
    padding: 30px 0;
  }
  & header{
    position: relative;
  }
  & header:after{
    transition: transform .2s ease;
    content: '';
    width: 10px;
    height: 20px;
    background: url(${arrow});
    position:absolute;
    top: calc(50% - 10px);
    right: -20px;
    ${({showAnswer}) => showAnswer ? "transform: rotate(-90deg);" : "transform: rotate(0);"}
  }
  
  @media (max-width: 320px) {
    & p {
      width: 187px;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
    }
  }
`
const Answer = styled.div`
  overflow: hidden;
  transition: height .3s ease;
  ${
    ({showAnswer}) => showAnswer
    ? "display: block; height: auto;"
    : "height: 0;"
    }
`
export default CommonInfo;