import React, {useState, useEffect} from "react";
import styled from 'styled-components'
import axios from 'axios';
import ReactPixel from 'react-facebook-pixel';

const ProposalForm = ({closePopup, mainWrapper}) => {

    const [companyName, setCompanyName] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [customerMail, setCustomerMail] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");
    const [customerCity, setCustomerCity] = useState("");
    const [personalData, setPersonalData] = useState("");
    const [checkAllCondition, setCheckAllCondition] = useState(false);
    const [response, setResponse] = useState({status: "", info: ""});
    const [emailField, setEmailField] = useState({});

    useEffect(() => {
        setCheckAllCondition(() => {
            return [
                companyName,
                customerName,
                customerMail,
                customerPhone,
                customerCity,
                personalData
            ].reduce((acc, el) => {
                if (!el) {
                    acc = false;
                }
                return acc;
            }, true);
        })
    }, [companyName, customerName, customerMail, customerPhone, customerCity, personalData])

    useEffect(() => {
        if(response.status){
            new Promise((resolve) => {
                setTimeout(() => {
                    (() => {
                        resolve();
                    })()
                }, 2000)
            }).then(() => {
                ReactPixel.trackCustom('application for a new kitchen');
                closePopup(mainWrapper);
            })
        }
    }, [response])

    useEffect(() => {
        setEmailField(document.getElementById('mailField'))
    }, [])

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const formHandler = (type, value, target) => {
        switch (type) {
            case "companyName":
                setCompanyName(value);
                break;
            case "customerName":
                setCustomerName(value);
                break;
            case "customerMail":
                if(validateEmail(value)){
                    emailField.setAttribute('style', 'border: 1px solid black;');
                }
                setCustomerMail(value);
                break;
            case "customerPhone":
                setCustomerPhone(value);
                break;
            case "customerCity":
                setCustomerCity(value);
                break;
            case "personalData":
                setPersonalData(prev => !prev);
                break;
        }
    }
    const submitHandler = e => {
        if(!validateEmail(customerMail)) {
            emailField.setAttribute('style', 'border: 1px solid red;')
            return;
        }

        if (checkAllCondition) {
            const data = {
                nameUser: companyName,
                emailUser: customerMail,
                sonameUser: customerName,
                phoneUser: customerPhone,
                cityUser: customerCity,
            };
            axios
                .post("/send2.php", data)
                .then((res) => {
                    setResponse({
                        status: res.status,
                        info: res.data,
                    })
                })
                .catch((error) => {
                    alert(error)
                    setResponse({
                        status: error.status,
                        info: error.data,
                    })
                });
        }
    }

    return (
        <FormWrapper onClick={e => e.stopPropagation()}>
            <form id='modalForm' onSubmit={e => e.preventDefault()}>
                <FormHeader>
                    <h2>Заявка на заключение договора</h2>
                    <p>Мы свяжемся с вами, чтобы обсудить детали</p>
                </FormHeader>
                <InputWrapper>
                    <input type="text" placeholder="*Название компании" onChange={e => formHandler("companyName", e.target.value)} value={companyName}/>
                    <input type="text" placeholder="*Имя, фамилия" onChange={e => formHandler("customerName", e.target.value)} value={customerName}/>
                    <input type="email" required id='mailField' placeholder="*Эл. почта" onChange={e => formHandler("customerMail", e.target.value, e.target)} value={customerMail}/>
                    <input type="tel" required placeholder="*Телефон" onChange={e => formHandler("customerPhone", e.target.value)} value={customerPhone}/>
                    <input type="text" placeholder="*Город" onChange={e => formHandler("customerCity", e.target.value)} value={customerCity}/>
                    <PersonalDataCheck>
                        <p>*Согласие на обработку персональных данных</p>
                        <input type="checkbox" onChange={e => formHandler("personalData", e.target.value)} checked={personalData}/>
                    </PersonalDataCheck>
                </InputWrapper>
                <SubmitButton onClick={e => submitHandler(e)} check={checkAllCondition}>Отправить</SubmitButton>
            </form>
            {
                response.status
                    ? response.status === 200
                    ? <SubModal status={true}><h2>Спасибо за заявку! Оператор свяжется с Вами в ближайшее время</h2></SubModal>
                    : <SubModal status={false}><h2>Возникли проблемы, попробуйте позже</h2></SubModal>
                    : null
            }
        </FormWrapper>
    )
}

const SubModal = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(68,68,68);
  animation: .5s ease 0s showSubPopup;
  
  @keyframes showSubPopup { from { top: -100%; } to { top: 0; }  }
  
  h2{
    color: white;
    font-size: 26px;
    text-align: center;
    padding: 30px;
  }
  
  @media(max-width: 768px) {
    h2 {
      font-size: 18px;
    }
  }
`
const FormWrapper = styled.div`
  position:relative;
  padding: 30px;
  width: 35%;
  background: #fffff9;
  border-radius: 6px;
  z-index: 99;
  animation: .2s ease showForm;
  overflow: hidden;
  max-width: 600px;
  
  form{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  @keyframes showForm { from { opacity: 0; margin-bottom: 50px; } to { opacity: 1; margin-bottom: 0; }  }
  
  @media (max-width: 1280px) {
    width: 50%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
  @media(max-width: 576px) {
    padding: 10px;
  }
`
const FormHeader = styled.div`
  text-align: center;
  margin-bottom: 20px;
  & h2 {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
  }
  @media (max-width: 576px) {
    & h2 {
      font-size: 18px;
    }
    & p {
    font-size: 14px;
    }
  }
`
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 80px;
  max-width: 600px;
  
  & input {
    padding: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    border: 1px solid black;
    border-radius: 5px;
    outline: none;
    
    :last-child{
    margin-bottom: 0;
    }
  }
  
  @media (max-width: 1280px) {
    padding: 20px 40px;
  }
  @media (max-width: 1024px) {
    padding: 20px 30px;
  }
  @media (max-width: 768px) {
    padding: 20px 70px;
  }
  @media  (max-width: 576px) {
    padding: 20px 20px;
    
    & input {
      padding: 5px;
      font-size: 16px;
    }
  }
`
const PersonalDataCheck = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  
  & input {
  margin-left: 10px;
  }
`
const SubmitButton = styled.button`
  background: ${({check}) => check ? "#9bff7c" : "rgba(0,0,0,0.46)"};
  width: 150px;
  padding: 10px;
  border: 2px solid rgba(255,255,255,0);
  border-radius: 6px;
  align-self: center;
  margin-top: 10px;
  font-size: 18px;
  color: ${({check}) => check ? "#474747" : "#fff"};
  transition: all .2s ease;
  user-select: none;
  
  ${
    ({check}) => check 
        ? ":hover{ border: 2px solid #34c058; background: #83ff33; } :active{ background: #fcffee; color: black; }"
        : null
}
`
const FormPopup = ({setShowPopup}) => {

    const [wrapperRef, setWrapperRef] = useState();
    const animationDuration = 400;


    const closePopup = (element) => {
        element.setAttribute('style', 'animation: .5s ease dropPopup;');
        setTimeout(() => {
            setShowPopup(false)
        }, animationDuration);
    }

    useEffect(() => {
        setWrapperRef(document.querySelector(`.${FormPopupWrapper.styledComponentId}`));
    },[])

    return (
        <FormPopupWrapper onClick={e => closePopup(e.target)}>
            <ProposalForm closePopup={closePopup} mainWrapper={wrapperRef}/>
        </FormPopupWrapper>
    )
}

const FormPopupWrapper = styled.div`
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.40);
  animation: .5s ease showPopup;
  z-index: 9999;
  
  @media (max-width: 1280px) {
    align-items: flex-start;
    padding-top: 70px;
  }
  @media (max-width: 768px) {
    //align-items: flex-start;
    //padding-top: 70px;
  }
  
  
  @keyframes showPopup { from { opacity: 0; } to { opacity: 1; }  }
  @keyframes dropPopup { from { opacity: 1; } to { opacity: 0; }  }
`


export default FormPopup;
