import React from "react";
import styled from 'styled-components';
import {SectionHeader, Container} from '../CommonStyles';
//img
import example1 from '../../resource/how_works/example1.png'
import example2 from '../../resource/how_works/example2.png'
import example3 from '../../resource/how_works/example3.png'
import example4 from '../../resource/how_works/example4.png'
import example5 from '../../resource/how_works/example5.png'
import timeBar1 from '../../resource/how_works/time_bar1.svg'
import timeBar2 from '../../resource/how_works/time_bar2.svg'


const ExampleCardComponent = ({img, header, description = ""}) => {
    return (
        <ExampleCard>
            <img src={img} alt="example pic"/>
            <header><h3>{header}</h3></header>
            <p>
                {description}
            </p>
        </ExampleCard>
    )
}
const HowWeWorks = (props) => {

    return (
        <MainWrapper>
            <Example>
                <Container>
                    <Description>
                        <SectionHeader>
                            Как мы работаем
                        </SectionHeader>
                    </Description>
                    <ExampleWork>
                        <ExampleCardComponent
                            img={example1}
                            header="1. Вы публикуете меню на день"
                            description="После заключения договора партнёр имеет возможность через специальное приложение “Кухня” или в личном кабинете на сайте опубликовать меню на тот или иной день. Опубликованное меню становится доступным для заказа в приложении “Сытый” или на сайте (Заказы принимаются исключительно онлайн, мы не принимаем заказы по телефону)"
                        />
                        <ExampleCardComponent
                            img={example2}
                            header="2. Клиент делают заказы, мы их подтверждаем"
                            description="При оформлении заказа клиент указывает период времени когда желает получить еду. На каждый период заявки принимаются до определённого момента."
                        />
                        <ExampleCardComponent
                            img={example3}
                            header="3. Отправляем вам итоговый пул заказов"
                            description="В установленное время (как правило через 35-45 минут с момента отправки вам заказа) к вам приезжает курьер(ы) и забирает готовую еду (в некоторых случаях доставку от кухни "
                        />
                        <ExampleCardComponent
                            img={example4}
                            header="4. В установленное время..."
                            description="Вы собираете заказы и готовите их к передаче курьеру. Необходимое время для сборки заказов определяется на этапе заключения договора. В некоторых случаях доставку собранных заказов до нашего распределительного центра осуществляет сам партнёр"
                        />
                        <ExampleCardComponent
                            img={example4}
                            header="5. В 10:45 курьер забирает заказы"
                            description="Заказы обрабатываются в РЦ Все заказы доставляются в ближайщий распределительный центр для сортировки и дальнейшей доставки"
                        />
                        <ExampleCardComponent
                            img={example5}
                            header="5. Курьеры доставляют заказы по своим маршрутам"
                            description="Вы контролируете сроки доставки каждого заказа"
                        />
                    </ExampleWork>
                </Container>
            </Example>
            <Attachment>
                <Container>
                    <AttachmentWrapper>
                        <header>
                            <h3>Пример доставки в обеденное время</h3>
                        </header>
                        <p>
                            В качестве примера возьмём период доставки с 11:30 до 12:30. Традиционно приём заказов заканчивается за 1,5 часа до начала доставки (может быть скорректирован индивидуально). Т.е. для периода доставки с 11:30 до 12:30 сайт и приложение не позволит оформить заказ после 10:00.
                        </p>
                        <p>
                            В 10:00 на вашу электронную почту отправляется финальный и подтверждённый нами заказ. Ваша задача - приготовить и собрать все заказы в течении 45 минут и передать курьеру. На этом ваша работа по данному периоду доставки завершена.
                        </p>
                    </AttachmentWrapper>
                </Container>
            </Attachment>
            <Delivery>
                <Container>
                    <SectionHeader>Доставка обедов</SectionHeader>
                    <p>Доставка с 11:30 — 13:00</p>
                </Container>
                <TimeBar>
                    <img src={timeBar1} alt="time bar"/>
                </TimeBar>
                <Container>
                    <SectionHeader>Доставка ужинов</SectionHeader>
                    <p>Доставка с 18:30 — 19:30</p>
                </Container>
                <TimeBar>
                    <img src={timeBar2} alt="time bar"/>
                </TimeBar>
            </Delivery>
        </MainWrapper>
    )
}
const MainWrapper = styled.div`

`
const Example = styled.div`

`
const Description = styled.section`
  padding: 66px 0;
  & p {
    margin-top: 28px;
    width: 870px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
  } 
  @media (max-width: 1280px) {
    & p {
      width: 570px
    }
  }
  @media (max-width: 576px) {
    & p {
      width: 100%;
    }
  }
`

const ExampleWork = styled.div`
  padding: 31px 0 47px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 97px;
  grid-row-gap: 100px;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 50px;
  }
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
  }
`
const ExampleCard = styled.div`
  
  & header {
    margin-top: 27px;
    
    & h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }
  }
  & p {
    margin-top: 36px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
  }
`
const Attachment = styled.section`
  background: #FAFAFA;
  padding: 46px 0;
`
const AttachmentWrapper = styled.div`
  position: relative;
  padding-left: 21px;
  & h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
  
  & p {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    margin-top: 26px;
  }
  
  :before {
    content: '';
    position:absolute;
    width: 10px;
    height: 100%;
    left: 0;
    top: 0;
    background: #D1E231;
    border-radius: 66px;
  }
`
const Delivery = styled.section`
  p {
    margin-top: 25px;
  }
  padding: 143px 0 214px 0;
  
  @media(max-width: 576px) {
    padding: 50px 0 185px 0;
  }
`
const TimeBar = styled.div`
  padding: 30px 0;
  overflow: scroll;
  & img {
    border-radius: 6px;
  }
  // firefox scrollbar remove
  scrollbar-width: none;
  // webkit scrollbar remove
  ::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
  @media(min-width: 1280px) {
    display: flex;
    justify-content: center;
  }
`

export default HowWeWorks;