import React from "react";
import styled from 'styled-components';
import {SectionHeader, Container} from '../CommonStyles';
//img
import template from '../../resource/how_picture/image_tmeplate.svg';
import good from '../../resource/how_picture/bad.svg'
import bad from '../../resource/how_picture/good.svg'
import ex1_bad from '../../resource/how_picture/ex1_bad.png'
import ex2_bad from '../../resource/how_picture/ex2_bad.png'
import ex3_bad from '../../resource/how_picture/ex3_bad.png'
import ex4_bad from '../../resource/how_picture/ex4_bad.png'
import ex5_bad from '../../resource/how_picture/ex5_bad.png'
import ex6_bad from '../../resource/how_picture/ex6_bad.png'
import ex7_bad from '../../resource/how_picture/ex7_bad.png'
import ex8_bad from '../../resource/how_picture/ex8_bad.png'
import ex9_bad from '../../resource/how_picture/ex9_bad.png'
import ex10_bad from '../../resource/how_picture/ex10_bad.png'
import ex11_bad from '../../resource/how_picture/ex11_bad.png'
import ex1_good from '../../resource/how_picture/ex1_good.png'
import ex2_good from '../../resource/how_picture/ex2_good.png'
import ex3_good from '../../resource/how_picture/ex3_good.png'
import ex4_good from '../../resource/how_picture/ex4_good.png'
import ex5_good from '../../resource/how_picture/ex5_good.png'
import ex6_good from '../../resource/how_picture/ex6_good.png'
import ex7_good from '../../resource/how_picture/ex7_good.png'
import ex8_good from '../../resource/how_picture/ex8_good.png'
import ex9_good from '../../resource/how_picture/ex9_good.png'
import ex10_good from '../../resource/how_picture/ex10_good.png'
import ex11_good from '../../resource/how_picture/ex11_good.png'
import preview1 from '../../resource/how_picture/preview1.png'
import preview2 from '../../resource/how_picture/preview2.png'
import preview3 from '../../resource/how_picture/preview3.png'
import preview4 from '../../resource/how_picture/preview4.png'
import preview5 from '../../resource/how_picture/preview5.png'
import preview6 from '../../resource/how_picture/preview6.png'
import preview7 from '../../resource/how_picture/preview7.png'
import preview8 from '../../resource/how_picture/preview8.png'
import preview9 from '../../resource/how_picture/preview9.png'
import preview10 from '../../resource/how_picture/preview10.png'
import preview11 from '../../resource/how_picture/preview11.png'

const HowMakePicture = (props) => {

    return (
        <MainWrapper>
            <Preview>
                <Container>
                    <h3>
                        Как сделать хорошие фотографии еды
                    </h3>
                </Container>
            </Preview>
            <Instruction>
                <Container>
                    <InstructionWrapper>
                        <div>
                            <SectionHeader>Технические требования</SectionHeader>
                            <p>Только JPEG со сжатием не ниже 80% или PNG.</p>
                            <p>Соотношения сторон 1:1, минимум 1200 пикселей по ширине.</p>
                            <p>Фото должно быть четкое. Запрещено использовать размытие, эффекты и фильтры</p>
                        </div>
                        <div>
                            <img src={template} alt="image template"/>
                        </div>
                    </InstructionWrapper>
                </Container>
            </Instruction>
            <DishInstruction>
                <Container>
                    <SectionHeader>Блюда в меню</SectionHeader>
                    <DishInstructionWrapper>
                        <InstructionItem>
                            <Clarification>
                                <header><h4>1. Строго одно блюдо в кадре</h4></header>
                                <p>Блюдо должно быть готовым и с верным составом. Если к блюду прилагается дополнительный ингредиент (хлеб или соус), он тоже должен быть в кадре</p>
                                <Example>
                                    <ExampleWrapper>
                                        <img src={ex1_good} alt="dish good"/>
                                        <div>
                                            <img src={good} alt="good variant"/>
                                        </div>
                                    </ExampleWrapper>
                                    <ExampleWrapper>
                                        <img src={ex1_bad} alt="dish bad"/>
                                        <div>
                                            <img src={bad} alt="bad variant"/>
                                        </div>
                                    </ExampleWrapper>
                                </Example>
                            </Clarification>
                            <Illustration>
                                <img src={preview1} alt="preview"/>
                            </Illustration>
                        </InstructionItem>
                        <InstructionItem>
                            <Clarification>
                                <header><h4>2. Запрещено использовать на фотографии водяные знаки, плашки, объявления, скидки, промокоды, любые надписи, логотипы</h4></header>
                                <Example>
                                    <ExampleWrapper>
                                        <img src={ex2_good} alt="dish good"/>
                                        <div>
                                            <img src={good} alt="good variant"/>
                                        </div>
                                    </ExampleWrapper>
                                    <ExampleWrapper>
                                        <img src={ex2_bad} alt="dish bad"/>
                                        <div>
                                            <img src={bad} alt="bad variant"/>
                                        </div>
                                    </ExampleWrapper>
                                </Example>
                            </Clarification>
                            <Illustration>
                                <img src={preview2} alt="preview"/>
                            </Illustration>
                        </InstructionItem>
                        <InstructionItem>
                            <Clarification>
                                <header><h4>3. Не удаляйте и не дорисовывайте тени</h4></header>
                                <p>Все, что можно с ней сделать — немного осветлить</p>
                                <Example>
                                    <ExampleWrapper>
                                        <img src={ex3_good} alt="dish good"/>
                                        <div>
                                            <img src={good} alt="good variant"/>
                                        </div>
                                    </ExampleWrapper>
                                    <ExampleWrapper>
                                        <img src={ex3_bad} alt="dish bad"/>
                                        <div>
                                            <img src={bad} alt="bad variant"/>
                                        </div>
                                    </ExampleWrapper>
                                </Example>
                            </Clarification>
                            <Illustration>
                                <img src={preview3} alt="preview"/>
                            </Illustration>
                        </InstructionItem>
                        <InstructionItem>
                            <Clarification>
                                <header><h4>4. Показывайте только реальные порции</h4></header>
                                <p>
                                    Например, если в порции 8 роллов, то на фотографии должно быть 8 роллов.
                                    Можно положить в кадр столовые приборы, чтобы подчеркнуть реальные размеры блюда.
                                </p>
                                <Example>
                                    <ExampleWrapper>
                                        <img src={ex4_good} alt="dish good"/>
                                        <div>
                                            <img src={good} alt="good variant"/>
                                        </div>
                                    </ExampleWrapper>
                                    <ExampleWrapper>
                                        <img src={ex4_bad} alt="dish bad"/>
                                        <div>
                                            <img src={bad} alt="bad variant"/>
                                        </div>
                                    </ExampleWrapper>
                                </Example>
                            </Clarification>
                            <Illustration>
                                <img src={preview4} alt="preview"/>
                            </Illustration>
                        </InstructionItem>
                        <InstructionItem>
                            <Clarification>
                                <header><h4>5. Использовать естественный, дневной свет либо его имитацию</h4></header>
                                <p>
                                    Если нет возможности поставить студийный свет, фотографируйте у окна. При слишком ярком солнце окно можно закрыть неплотной тканью, чтобы избежать резких теней.
                                </p>
                                <p>
                                    Баланс белого — нейтральный или в теплом диапазоне.
                                </p>
                                <Example>
                                    <ExampleWrapper>
                                        <img src={ex5_good} alt="dish good"/>
                                        <div>
                                            <img src={good} alt="good variant"/>
                                        </div>
                                    </ExampleWrapper>
                                    <ExampleWrapper>
                                        <img src={ex5_bad} alt="dish bad"/>
                                        <div>
                                            <img src={bad} alt="bad variant"/>
                                        </div>
                                    </ExampleWrapper>
                                </Example>
                            </Clarification>
                            <Illustration>
                                <img src={preview5} alt="preview"/>
                            </Illustration>
                        </InstructionItem>
                        <InstructionItem>
                            <Clarification>
                                <header><h4>6. Запрещено показывать алкоголь и табачные изделия</h4></header>
                            </Clarification>
                        </InstructionItem>
                    </DishInstructionWrapper>
                </Container>
            </DishInstruction>
            <CompositionInstruction>
                <Container>
                    <SectionHeader>Композиция и фон</SectionHeader>
                    <InstructionItem>
                        <Clarification>
                            <header><h4>1. Используйте центральную композицию</h4></header>
                            <Example>
                                <ExampleWrapper>
                                    <img src={ex6_good} alt="dish good"/>
                                    <div>
                                        <img src={good} alt="good variant"/>
                                    </div>
                                </ExampleWrapper>
                                <ExampleWrapper>
                                    <img src={ex6_bad} alt="dish bad"/>
                                    <div>
                                        <img src={bad} alt="bad variant"/>
                                    </div>
                                </ExampleWrapper>
                            </Example>
                        </Clarification>
                        <Illustration>
                            <img src={preview6} alt="preview"/>
                        </Illustration>
                    </InstructionItem>
                    <InstructionItem>
                        <Clarification>
                            <header><h4>2. Минимум 50% блюда должно быть в кадре</h4></header>
                            <Example>
                                <ExampleWrapper>
                                    <img src={ex7_good} alt="dish good"/>
                                    <div>
                                        <img src={good} alt="good variant"/>
                                    </div>
                                </ExampleWrapper>
                                <ExampleWrapper>
                                    <img src={ex7_bad} alt="dish bad"/>
                                    <div>
                                        <img src={bad} alt="bad variant"/>
                                    </div>
                                </ExampleWrapper>
                            </Example>
                        </Clarification>
                        <Illustration>
                            <img src={preview7} alt="preview"/>
                        </Illustration>
                    </InstructionItem>
                    <InstructionItem>
                        <Clarification>
                            <header><h4>3. Оставляйте небольшой запас свободного места по краям кадра</h4></header>
                            <Example>
                                <ExampleWrapper>
                                    <img src={ex8_good} alt="dish good"/>
                                    <div>
                                        <img src={good} alt="good variant"/>
                                    </div>
                                </ExampleWrapper>
                                <ExampleWrapper>
                                    <img src={ex8_bad} alt="dish bad"/>
                                    <div>
                                        <img src={bad} alt="bad variant"/>
                                    </div>
                                </ExampleWrapper>
                            </Example>
                        </Clarification>
                        <Illustration>
                            <img src={preview8} alt="preview"/>
                        </Illustration>
                    </InstructionItem>
                    <InstructionItem>
                        <Clarification>
                            <header><h4>4. Лучше всего использовать простой фон</h4></header>
                            <p>
                                Идеально подойдут белая скатерть, однотонные салфетки или поверхность простого деревянного стола
                            </p>
                            <Example>
                                <ExampleWrapper>
                                    <img src={ex9_good} alt="dish good"/>
                                    <div>
                                        <img src={good} alt="good variant"/>
                                    </div>
                                </ExampleWrapper>
                                <ExampleWrapper>
                                    <img src={ex9_bad} alt="dish bad"/>
                                    <div>
                                        <img src={bad} alt="bad variant"/>
                                    </div>
                                </ExampleWrapper>
                            </Example>
                        </Clarification>
                        <Illustration>
                            <img src={preview9} alt="preview"/>
                        </Illustration>
                    </InstructionItem>
                    <InstructionItem>
                        <Clarification>
                            <header><h4>5. Посуда лучше всего подойдет без принтов или узоров</h4></header>
                            <Example>
                                <ExampleWrapper>
                                    <img src={ex10_good} alt="dish good"/>
                                    <div>
                                        <img src={good} alt="good variant"/>
                                    </div>
                                </ExampleWrapper>
                                <ExampleWrapper>
                                    <img src={ex10_bad} alt="dish bad"/>
                                    <div>
                                        <img src={bad} alt="bad variant"/>
                                    </div>
                                </ExampleWrapper>
                            </Example>
                        </Clarification>
                        <Illustration>
                            <img src={preview10} alt="preview"/>
                        </Illustration>
                    </InstructionItem>
                    <InstructionItem>
                        <Clarification>
                            <header><h4>6. В кадр не должны попадать руки и прочие части тела, а также люди целиком</h4></header>
                            <Example>
                                <ExampleWrapper>
                                    <img src={ex11_good} alt="dish good"/>
                                    <div>
                                        <img src={good} alt="good variant"/>
                                    </div>
                                </ExampleWrapper>
                                <ExampleWrapper>
                                    <img src={ex11_bad} alt="dish bad"/>
                                    <div>
                                        <img src={bad} alt="bad variant"/>
                                    </div>
                                </ExampleWrapper>
                            </Example>
                        </Clarification>
                        <Illustration>
                            <img src={preview11} alt="preview"/>
                        </Illustration>
                    </InstructionItem>
                </Container>
            </CompositionInstruction>
        </MainWrapper>
    )
}

const MainWrapper = styled.div`

`
const Preview = styled.section`
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  padding: 100px 0;
  
  @media (max-width: 576px) {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    padding: 71px 0 32px 0;
    }
`
const Instruction = styled.section`
  background: #D1E231;
  padding: 50px 0;
  
  & p {
    margin-top: 20px;
  }
`
const InstructionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  &:last-child {
    padding-right: 150px;
    
    @media (max-width: 992px) {
      padding: 0;
    }
  }
  
  & img {
    @media (max-width: 576px) {
      display: none;
    }
  }
  
`
const DishInstruction = styled.section`
  padding: 100px  0;
`
const DishInstructionWrapper = styled.section`

`
const CompositionInstruction = styled.section`
  padding: 67px 0;
  background: #FAFAFA;
`
const InstructionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Clarification = styled.div`
  width: 708px;
  header {
    padding-top: 37px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }
  p {
    padding-top: 10px;
  }
  
  @media (max-width: 1280px) {
    width: 100%;
  }
`
const Example = styled.div`
  padding: 30px 0;
  display: flex;
  justify-content: space-around;
`
const ExampleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  & img {
    max-width: 300px;
    border: 1px solid #EEEEEE;
    
    @media (max-width: 1280px) {
      width: 250px;
    }
    @media (max-width: 992px) {
      width: 200px;
    }
    @media (max-width: 480px) {
      width: 145px;
    }
  }
  & div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 33px;
    height: 33px;
    background: #8BC34A;
    border-radius: 50%;
    
    & img {
      width: 13px;
      border: none;
    }
  }
  
  :last-child {
    & div {
      background: #F44336;
    }
  }
`
const Illustration = styled.div`
  & img {
    @media (max-width: 992px) {
      width: 250px;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
  
`

export default HowMakePicture;